.input-wrapper {
    background-color: #ffffff;
    width: 100%;
    border-radius: 10px;
    height: 2.5rem;
    padding: 0 15px;
    box-shadow: 0 0 8px #a5a5a5;
    display: flex;
    align-items: center;
}

.input {
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
    font-size: 1.25rem;
    margin-left: 5px;
    outline: none;
}

#search-icon {
    color: #4169e1
}