.organizations-list {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #a5a5a5;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 60vh;
    overflow-y: scroll;
}

.search-result {
    padding: 5px 10px;
}

.input {
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
    font-size: 1.25rem;
    margin-left: 5px;
    outline: black;
}