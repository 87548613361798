.Form {
    padding: 20px;
    border: 1px solid #000000;
    border-radius: 15px;
    width: 750px;
    max-width: 95vw;
  }
  
  .Outer {
    width: 750px;
    max-width: 95vw;
  }
  
  .Margin-Bottom {
    margin-bottom: 15px;
  }
  
  .Margin-Bottom-Small {
    margin-bottom: 3px;
  }
  
  .Center-Text {
    text-align: center;
  }
  
  .List {
    width: 750px;
    max-width: 95vw;
  }
  
  .Center-Outer {
    display: flex;
    justify-content: center;
  }
  
  .Details {
    padding: 20px;
    border: 1px solid #000000;
    border-radius: 15px;
    width: 750px;
    max-width: 95vw;
  }
  
  a {
    text-decoration: none;
  }

  .input-wrapper {
    background-color: #ffffff;
    width: 100%;
    border-radius: 10px;
    height: 2.5rem;
    padding: 0 15px;
    box-shadow: 0 0 8px #a5a5a5;
    display: flex;
    align-items: center;
}

#search-icon {
    color: #4169e1
}

.results-list {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #a5a5a5;
  border-radius: 10px;
  margin-top: 1rem;
  max-height: 60vh;
  overflow-y: scroll;
}

.search-result {
  padding: 5px 10px;
}

.search-result:hover {
  background-color: #efefef;
}

.results-text { 
  text-decoration: none; 
  color: #000000;
} 